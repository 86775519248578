import { fetcher } from '@root/api/hook'
import { ISPageWithArticle } from '@root/api/type'
import useSWR from 'swr'

export function usePageWithArticle() {
  const { data, error } = useSWR(`/page-with-article?populate=*`, fetcher)

  return {
    data: data as (ISPageWithArticle | undefined),
    isLoading: !error && !data,
    isError: !!error
  }
};