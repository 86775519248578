import { fetcher } from '@root/api/hook'
import { ISContactUs } from '@root/api/type'
import useSWR from 'swr'

export function useContactUs() {
  const { data, error } = useSWR(`/contact-us?populate=*`, fetcher)

  return {
    data: data as (ISContactUs | undefined),
    isLoading: !error && !data,
    isError: !!error
  }
}