import { fetcher } from '@root/api/hook'
import { ISLogo } from '@root/api/type'
import useSWR from 'swr'

export function useLogo() {
  const { data, error } = useSWR(`/logo?populate=*`, fetcher)

  return {
    data: data as (ISLogo | undefined),
    isLoading: !error && !data,
    isError: !!error
  }
}