import { fetcher } from '@root/api/hook'
import { ISCards } from '@root/api/type'
import useSWR from 'swr'

export function useCards() {
  const { data, error } = useSWR(`/cards?populate[background][populate]=*&populate[title][populate]=*&populate[article][fields][0]=id&populate[article][fields][1]=slug&sort=id:asc`, fetcher)

  return {
    data: data as (ISCards | undefined),
    isLoading: !error && !data,
    isError: !!error
  }
}