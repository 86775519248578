import { getStrapiMedia } from "@root/api/strapi";
import { useLogo } from "@root/hooks/useLogo"
import { Link } from "react-router-dom";

export default function Logo() {
  const { data: logoData } = useLogo();
  return (
    <Link to={logoData?.data?.attributes?.url || '#'}>
      <img src={getStrapiMedia(logoData?.data?.attributes?.imageSrc?.data?.attributes?.url || '#')} alt="MakerOps Logo" />
    </Link>
  )
}