import { FC, InputHTMLAttributes, PropsWithChildren, forwardRef, useRef } from "react";

interface IInputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  hint?: any;
}

export const InputField = forwardRef<HTMLInputElement, IInputFieldProps>(
  function({ className, children, hint, ...props }, ref) {
    const inputRef = useRef<HTMLInputElement | null>(null);
    return (
      <div className={`${className} relative`}>
        <input
          // className={`peer w-full px-[16px] pt-[26px] pb-[10px] bg-white rounded-[4px] focus:outline-none border ${hint ? 'border-[#EE2E5C] hover:border-[#EE2E5C] focus:border-[#EE2E5C]' : 'border-[rgba(0,0,0,0.15)] hover:border-black'} font-normal text-[16px] leading-[24px] input-field h-[60px]`}
          className={`block rounded-[4px] leading-[24px] px-4 pb-[10px] pt-[26px] w-full text-[16px] appearance-none focus:outline-none focus:ring-0 peer bg-white border ${hint ? 'border-[#EE2E5C] hover:border-[#EE2E5C] focus:border-[#EE2E5C]' : 'border-[rgba(0,0,0,0.15)] hover:border-black'}`}
          id={props.id}
          ref={(element) => {
            inputRef.current = element;
            if (typeof ref === "function") {
              ref(element);
            } else if(ref) { 
              ref.current = element;
            }
          }}
          {...props}
          placeholder=""
        />
        {children}
        <label htmlFor={props.id} className="absolute text-[16px] leading-[24px] text-[rgba(0,0,0,0.6)] duration-300 transform -translate-y-4 scale-75 top-5 z-10 origin-[0] start-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[rgba(0,0,0,0.6)] peer-focus:-translate-y-4 peer-placeholder-shown:text-[rgba(0,0,0,0.4)] rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">{props.placeholder}</label>
        {hint && (
          <div className="text-[12px] text-[#D40751] font-normal leading-[15px] mt-2">{hint}</div>
        )}
      </div>
    );
  }
);