import { FC, PropsWithChildren } from "react";
import RightArrowIcon from '../assets/right_arrow.svg';
import { Link } from "react-router-dom";

interface IQuestionButtonProps {
  url?: string;
  className?: string;
};

export const QuestionButton: FC<PropsWithChildren<IQuestionButtonProps>> = ({ children, url, className })  => {
  return (
    <Link to={url || '#'}>
      <div className={`${className} md:w-[354px] w-full md:pl-5 md:pr-4 md:py-4 py-[18px] flex justify-between md:border-[1px] border-y-[1px] bg-white border-black md:border-opacity-10 md:rounded-lg text-black hover:md:border-opacity-40 duration-300 ease-in-out transtion`}>
        <div className="flex flex-col text-left">
          <div className="text-[16px] font-semibold leading-6">Questions?</div>
          <div className="text-[16px] font-normal leading-6">
            {children}
          </div>
        </div>
        <div>
          <button className="w-[44px] h-[44px] rounded-full flex justify-center items-center p-0 bg-black hover:border-none focus:outline-none">
            <img src={RightArrowIcon} />
          </button>
        </div>
      </div>
    </Link>
  );
}