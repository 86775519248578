import { Link } from "react-router-dom";

export default function Error404Page() {
  return (
    <div
      className="text-center bg-white p-[40px] rounded-[10px] h-[100vh] flex flex-col justify-center items-center"
      style={{ boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)" }}
    >
      <div className="text-[100px] font-bold text-[#333] mb-[20px]">404</div>
      <div className="text-[24px] text-[#666] mb-[40px]">
        Oops! The page you're looking for doesn't exist.
      </div>
      <Link
        to="/"
        className="inline-block bg-[#007bff] text-white py-3 px-6 rounded-[5px] text-[16px] hover:text-white max-w-[350px] mb-[250px]"
        style={{ transition: "background-color 0.3s ease" }}
      >
        Go Back Home
      </Link>
    </div>
  );
}
