import { fetcher } from '@root/api/hook'
import { ISArticle } from '@root/api/type'
import useSWR from 'swr'

export function useArticle(articleId: number) {
  const { data, error } = useSWR(`/articles/${articleId}?populate=*&sort=id:asc`, fetcher)

  return {
    data: data as (ISArticle | undefined),
    isLoading: !error && !data,
    isError: !!error
  }
}