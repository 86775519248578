export const ELEMENT_S1 = 's1';

export const ELEMENT_S2 = 's2';

export const ELEMENT_S3 = 's3';

export const ELEMENT_S4 = 's4';

export const ELEMENT_S5 = 's5';

export const ELEMENT_S6 = 's6';

export const KEYS_SPACING = [
  ELEMENT_S1,
  ELEMENT_S2,
  ELEMENT_S3,
  ELEMENT_S4,
  ELEMENT_S5,
  ELEMENT_S6,
];
