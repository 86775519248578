export default function LoadingScreen() {
  return (
    <div className="flex flex-col items-center justify-center sm:min-h-[calc(100vh-240px)] min-h-[calc(100vh-320px)]">
        <div className="flex space-x-2">
            <div className="h-5 w-5 bg-blue-500 rounded-full transform scale-0 animate-chase"></div>
            <div className="h-5 w-5 bg-blue-500 rounded-full transform scale-0 animate-chase"></div>
            <div className="h-5 w-5 bg-blue-500 rounded-full transform scale-0 animate-chase"></div>
        </div>
        <div className="mt-1 text-center">
            <p className="text-lg font-semibold text-gray-700">
                Loading...
            </p>
            <p className="text-sm text-gray-500">
                We're getting things ready for you.
            </p>
        </div>
    </div>
);
}