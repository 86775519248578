import { fetcher } from '@root/api/hook'
import { ISProductPage } from '@root/api/type'
import useSWR from 'swr'

export function useProductPage() {
  const { data, error } = useSWR(`/product-page?populate=*`, fetcher)

  return {
    data: data as (ISProductPage | undefined),
    isLoading: !error && !data,
    isError: !!error
  }
}