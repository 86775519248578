import { getStrapiMedia } from "@root/api/strapi";
import ContactUs from "@root/components/ContactUs";
import { GuideButton } from "@root/components/GuideButton";
import LoadingScreen from "@root/components/LoadingScreen";
import { SectionTitle } from "@root/components/SectionTitle";
import BlogGroup from "@root/components/blog/BlogGroup";
import { useAboutPage } from "@root/hooks/useAboutPage";
import { Helmet } from 'react-helmet';

export default function AboutPage() {
  const { data: pageData, isLoading } = useAboutPage();

  if (isLoading)
    return <LoadingScreen />;

  return (
    <div className="text-black">
      <Helmet>
        <title>About Bloom ARS | Transforming Revenue Growth with Autonomous Revenue Systems</title>
        <meta name="description" content="Learn about Bloom’s mission to revolutionize revenue generation through AI. Discover our vision, values, and commitment to driving growth and efficiency with Autonomous Revenue Systems." />
      </Helmet>
      <div className="root-margin">
        <div className="lg:mx-[100px]">
          <div className="sm:pt-[200px] pt-[112px] font-p22 text-black mx-auto font-normal sm:text-[80px] text-[40px] sm:leading-[100px] leading-[56px] tracking-[-0.03em] max-sm:text-left">
            {pageData?.data?.attributes?.title}
          </div>
          <div className="sm:mt-10 mt-4 max-w-[688px] mx-auto font-normal sm:leading-[27px] sm:text-[18px] leading-[24px] text-[16px] text-center text-wrap max-sm:text-left">
            {pageData?.data?.attributes?.description}
          </div>
        </div>
        <img className="lg:max-w-[926px] lg:mx-auto sm:rounded-sm w-full max-sm:w-[100vw] max-sm:max-w-[100vw] max-sm:ml-[-16px] mt-[80px] max-sm:aspect-square max-sm:object-cover" src={getStrapiMedia(pageData?.data?.attributes?.headerImage?.data?.attributes?.url)} />
        <div className="lg:max-w-[688px] md:max-w-[622px] md:mx-auto w-full">
          <div className="mt-[80px] text-left">
            <SectionTitle>{pageData?.data?.attributes?.storyTitle}</SectionTitle>
            <div className="mt-6" dangerouslySetInnerHTML={{ __html: pageData?.data?.attributes?.storyContent || '' }}/>
            <div className="sm:mt-12 mt-10">
              <GuideButton url={pageData?.data.attributes.guideUrl} imageSrc={getStrapiMedia(pageData?.data.attributes.guideImage?.data?.attributes.url)}>
                Read more about our commitment to pushing the boundaries of AI-driven solutions
              </GuideButton>
            </div>
          </div>
          <div className="mt-[80px] text-left max-md:max-w-[582px]">
            <SectionTitle>{pageData?.data?.attributes?.guidePrincipleGroupTitle}</SectionTitle>
            <div className="mt-6">
              {pageData?.data?.attributes?.guidePrincipleGroupDescription}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="sm:mt-12 mt-8">
          <BlogGroup category="guidePrinciple" clickable={false} />
        </div>
      </div>
      <div className="root-margin">
        <div className="lg:max-w-[688px] md:max-w-[622px] md:mx-auto w-full mt-[120px]">
          <ContactUs />
        </div>
      </div>
    </div>
  );
}