import { getStrapiMedia } from "@root/api/strapi";
import { useEffect, useRef, useState } from "react";
import PauseIcon from '../assets/pause.svg';
import PlayIcon from '../assets/play.svg';
import ReplayIcon from '../assets/replay.svg';

interface IVideoPlayerProps {
  src?: string;
};

export default function VideoPlayer({ src }: IVideoPlayerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [status, setStatus] = useState('pause');

  const replayVideo = () => {
    if (videoRef.current) {
      videoRef.current.load();
      videoRef.current.play();
    }
  };

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const handleVideoEnd = () => {
    setStatus('end');
  };

  const onVideoPause = () => {
    setStatus('pause')
  };

  const onVideoPlaying = () => {
    setStatus('playing');
  };

  const handleControl = () => {
    if (status === 'playing') {
      pauseVideo();
    } else if (status === 'pause') {
      playVideo();
    } else {
      replayVideo();
    }
  };

  return (
    <div>
      <div className="tab-content bg-[#F2F2F2] sm:mt-16 mt-12 flex flex-col justify-between items-center" style={{ aspectRatio: 1400 / 934 }}>
        <video ref={videoRef} onEnded={handleVideoEnd} src={getStrapiMedia(src)} autoPlay={true} playsInline muted className="w-full h-full object-fill video-border hide-control" onPause={onVideoPause} onPlaying={onVideoPlaying} />
      </div>
      <div className="flex items-center gap-2 mt-[-60px] ml-5">
        <button className={`relative flex border-[1px] w-11 h-11 rounded-full border-black border-opacity-15 bg-white p-0 justify-center focus:outline-none items-center hover:border-black active:invert ${status === 'pause' ? 'pl-[2px]' : ''}`} onClick={handleControl}>
          <img src={status === 'playing' ? PauseIcon : (status === 'pause' ? PlayIcon : ReplayIcon)} />
        </button>
      </div>
    </div>
  );
}