import Logo from "./Logo";
import { Button } from "./Button";
import { MenuItem } from "./MenuItem";
import HamburgerIcon from '../assets/hamburger.svg';
import CloseIcon from '../assets/close.svg';
import { Link, useLocation } from "react-router-dom";
import { useContext, useState } from "react";
import { RootContext } from "@root/providers/RootProvider";
import { useCards } from "@root/hooks/useCards";
import { getUniqueSlug } from "@root/api/utils";

interface IMobileMenuItemProps {
  title: string;
  url: string;
  className?: string;
};

const MobileMenuItem = ({ title, url, className }: IMobileMenuItemProps) => {
  const { setMobileMenuVisible } = useContext(RootContext);
  return (
    <div className={`${className} text-[24px] font-p22 font-normal leading-[30px] tracking-[-0.24px]`}>
      <Link to={url} className="text-black hover:text-black" onClick={() => setMobileMenuVisible(false)}>{title}</Link>
    </div>
  );
}

export default function Header() {
  const { pathname } = useLocation();
  const { data: cards } = useCards();
  const { isMobileMenuVisible, setMobileMenuVisible } = useContext(RootContext);
  
  const menuItems = [
    {
      title: 'Product',
      url: '/product',
      hoverEffect: 'curve'
    },
    {
      title: 'About',
      url: '/about',
      hoverEffect: 'circle'
    },
    {
      title: 'Learn',
      url: '/learn',
      hoverEffect: 'arrow'
    },
  ];

  const onToggleMenu = () => {
    setMobileMenuVisible(!isMobileMenuVisible);
  };

  const getLearnCards = () => {
    return cards?.data?.filter(item => item?.attributes?.category === 'learn') || [];
  };

  const isMenuItemsVisible = () => {
    if (pathname.endsWith("/signup"))
      return false;
    return true;
  };

  return (
    <div className="fixed w-full bg-white z-[10000] top-0">
      <div className="py-[20px] border-b border-black flex justify-between items-center root-margin sm:h-20 h-12" id="header">
        <Logo />
        <div className={`flex gap-10 max-sm:hidden uppercase ${!isMenuItemsVisible() && 'md:hidden'}`}>
          {menuItems.map((item, index) => (
            <MenuItem key={index} url={item.url} active={pathname === item.url || index == 0 && pathname === '/'} hoverEffect={item.hoverEffect}>
              {item.title}
            </MenuItem>
          ))}
        </div>
        {isMenuItemsVisible() && (
          <div className="max-sm:hidden">
            <Button className="w-[118px] h-[40px] py-2" url='/signup'>Get started</Button>
          </div>
        )}
        {!isMenuItemsVisible() && (
          <div>
          </div>
        )}
        <div className="sm:hidden">
          <button className="bg-transparent p-0 border-none focus:outline-none" onClick={onToggleMenu}>
            {!isMobileMenuVisible ? <img src={HamburgerIcon} /> : <img src={CloseIcon} /> }
          </button>
        </div>      
      </div>
      {isMobileMenuVisible && (
        <div className="sm:hidden bg-white h-[calc(100vh-48px)] text-black text-left overflow-hidden">
          <div className="root-margin sm:hidden">
            <MobileMenuItem className="mt-12" title={menuItems[0].title} url={menuItems[0].url} />
            <MobileMenuItem className="mt-5" title={menuItems[1].title} url={menuItems[1].url} />
            <MobileMenuItem className="mt-8 text-xs tracking-[0.96px] uppercase !font-firstext" title={menuItems[2].title} url={menuItems[2].url} />
            <div className="flex flex-col gap-4 mt-5">
              {getLearnCards().map(card => (
                <MobileMenuItem key={card.id} title={card.attributes.title.text} url={card.attributes.url || getUniqueSlug(card.attributes.article.data.id, card.attributes.article.data.attributes.slug)} />
              ))}
            </div>
            <Button className="w-[146px] h-[48px] py-2 mt-10" url='/signup'>Get started</Button>
          </div>
          <div>
            <div className="w-[500px] h-[800px] rounded-full opacity-15 bg-gradient-to-r from-yellow-500 via-orange-500 to-purple-700 filter blur-[120px] animate-gradient">
            </div>
          </div>
        </div>
      )}
    </div>
  )
}