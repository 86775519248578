import qs from "qs";
import { Environment } from "./constants";

export async function fetchAPI(
  path: string,
  urlParamsObject = {},
  options = {},
) {
  let requestUrl = "";

  try {
    // Merge default and user options
    const token = Environment.STRAPI_API_TOKEN;
    const mergedOptions = {
      next: { revalidate: 60 },
      headers: {
        Authorization:  `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      ...options,
    };

    // Build request URL
    const queryString = qs.stringify(urlParamsObject);
    requestUrl = `${Environment.STRAPI_API_URL}${path}${
      queryString ? `?${queryString}` : ""
    }`;

    // Trigger API call
    const response = await fetch(requestUrl, mergedOptions);
    const data = await response.json();
    return data;
  } catch (error: any) {
    console.error(`fetchAPI error: ${requestUrl}`, error.message);
    throw new Error(
      `Please check if your server is running and you set all the required tokens.`,
    );
  }
}

export function getStrapiMedia(url: string| undefined) {
  if (url == null) {
    return undefined;
  }

  if (url.startsWith("http")) {
    return url;
  }

  // // Return the full URL if the media is hosted on an external provider
  // if (url.startsWith("http") || url.startsWith("//")) {
  //   return url;
  // }

  // Otherwise prepend the URL path with the Strapi URL
  // return `${Environment.CLOUDFRONT_BASE_URL}${url}`;

  // Otherwise extract the pathname and prepend the URL path with the Strapi URL
  try {
    const parsedUrl = new URL(url, Environment.CLOUDFRONT_BASE_URL);
    return `${Environment.CLOUDFRONT_BASE_URL}${parsedUrl.pathname}`;
  } catch (error) {
    // In case of an invalid URL, return the original url or handle it as needed
    console.error('Invalid URL:', error);
    return url;
  }

}
