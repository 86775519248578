import { fetcher } from '@root/api/hook'
import { ISLearnPage } from '@root/api/type'
import useSWR from 'swr'

export function useLearnPage() {
  const { data, error } = useSWR(`/learn-page?populate=*`, fetcher)

  return {
    data: data as (ISLearnPage | undefined),
    isLoading: !error && !data,
    isError: !!error
  }
}