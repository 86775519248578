import { fetcher } from '@root/api/hook'
import { ISSignupPage } from '@root/api/type'
import useSWR from 'swr'

export function useSignupPage() {
  const { data, error } = useSWR(`/signup-page?populate[backgroundImage][populate]=*&populate[foregroundImage][populate]=*`, fetcher)

  return {
    data: data as (ISSignupPage | undefined),
    isLoading: !error && !data,
    isError: !!error
  }
}