import { ReactNode, createContext, useState } from "react";

interface IRootContextProps {
  isMobileMenuVisible: boolean;
  setMobileMenuVisible: (visible: boolean) => void;
  rootMargin: number;
  setRootMargin: (rootMargin: number) => void;
};

export const RootContext = createContext<IRootContextProps>({
  isMobileMenuVisible: false,
  setMobileMenuVisible: () => {},
  rootMargin: 0,
  setRootMargin: () => {}
});

interface IRootProviderProps {
  children: ReactNode;
};

export function RootProvider({ children }: IRootProviderProps) {
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [rootMargin, setRootMargin] = useState(0);
  return (
    <RootContext.Provider value={{ isMobileMenuVisible, setMobileMenuVisible, rootMargin, setRootMargin }}>
      {children}
    </RootContext.Provider>
  )
}