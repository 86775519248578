import type { RegisterOptions } from 'react-hook-form';
import { IUser } from './form';
import { validateBusinessEmail, validateEmail } from './utils';

export const emailValidatorOptions: RegisterOptions<IUser, 'email'> = {
  required: {
    value: true,
    message: 'Please enter an email'
  },
  validate: (email: string) => {
    if (!validateEmail(email)) {
      return 'Please enter a valid email address';
    }
    if (!validateBusinessEmail(email)) {
      return 'Please enter a business email address';
    }
    return true;
  }
};

export const firstNameValidatorOptions: RegisterOptions<IUser, 'firstName'> = {
  required: {
    value: true,
    message: 'Please enter a first name'
  }
};

export const lastNameValidatorOptions: RegisterOptions<IUser, 'lastName'> = {
  required: {
    value: true,
    message: 'Please enter a last name'
  }
};
