import { useEffect, useContext, useLayoutEffect } from "react";
import "./App.css";
import Router from "./routes/Router";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { RootContext } from "./providers/RootProvider";
import { useLocation } from "react-router-dom";
import { TooltipProvider } from "./components/plate/plate-ui/tooltip";

function App() {
  const { isMobileMenuVisible, setRootMargin } = useContext(RootContext);
  const { pathname } = useLocation();

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(handleResize, 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleResize = () => {
    const headerDom = document.getElementById("header");
    if (headerDom) {
      setRootMargin(headerDom.offsetLeft || 0);
      document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth + 2) + "px");
    }
  };

  const isHeaderVisible = () => {
    if (pathname === '/not-found') return false;
    return true;
  };

  const isFooterVisible = () => {
    if (pathname === "/signup" || pathname === '/not-found') return false;
    return true;
  };

  return (
    <div className="font-firstext">
      {isHeaderVisible() && <Header />}
      <div className={`${isMobileMenuVisible && "max-sm:hidden"}`}>
        <TooltipProvider
          disableHoverableContent
          delayDuration={500}
          skipDelayDuration={0}
        >
          <Router />
        </TooltipProvider>
        {isFooterVisible() && <Footer />}
      </div>
    </div>
  );
}

export default App;
