import { fetcher } from '@root/api/hook'
import { ISFooter } from '@root/api/type'
import useSWR from 'swr'

export function useFooter() {
  const { data, error } = useSWR(`/footer?populate[socialProfiles][populate]=*`, fetcher)

  return {
    data: data as (ISFooter | undefined),
    isLoading: !error && !data,
    isError: !!error
  }
}