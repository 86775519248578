import { getStrapiMedia } from "@root/api/strapi";
import { getIdFromSlug } from "@root/api/utils";
import BlogGroup from "@root/components/blog/BlogGroup";
import LoadingScreen from "@root/components/LoadingScreen";
import PlateEditor from "@root/components/plate/plate-editor";
import { useArticle } from "@root/hooks/useArticle";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import '@root/assets/styles/article.css';
import { Helmet } from "react-helmet";

export default function ArticlePage() {
  const { articleSlug } = useParams();
  const { data: article, isError, isLoading } = useArticle(getIdFromSlug(articleSlug || ''));
  const headerImageRef = useRef<HTMLImageElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      navigate('/not-found', { replace: true });
    }
  }, [isError]);

  useEffect(() => {
    document.title = `Bloom ARS | Article`;
  }, []);

  const getInitialValue = () => {
    const defaultValue = [
      {
        id: '1',
        type: ELEMENT_PARAGRAPH,
        children: [{ text: '' }],
      }
    ];
    try {
      return JSON.parse(article?.data.attributes.blogContent || '');
    } catch (err) {
      return defaultValue;
    }
  }

  if (isLoading)
    return <LoadingScreen />;

  return (
    <div className="text-black root-margin">
      {article && (
        <Helmet>
          <title>{article.data.attributes.title}</title>
          <meta name="description" content={article.data.attributes.description} />
        </Helmet>
      )}
      <div className="md:mx-[100px] sm:mt-[200px] mt-[112px]">
        {!article?.data.attributes.textOnly && (
          <>
            <div className="max-w-[688px] font-p22 text-black mx-auto font-normal sm:text-[64px] text-[32px] sm:leading-[80px] leading-[44px] tracking-[-0.02em] max-sm:text-left max-sm:font-medium">
              {article?.data.attributes.title}
            </div>
            <div className="mt-6 font-medium text-sm text-[rgba(0,0,0,0.6)] text-center max-sm:text-left">
              {article?.data.attributes.articleLength} minute read
            </div>
            <div className="mt-12 max-w-[688px] mx-auto font-normal leading-[27px] text-lg text-center text-wrap max-sm:text-left">
              {article?.data.attributes.description}
            </div>
            <img
              ref={headerImageRef}
              className="md:max-w-[926px] md:mx-auto sm:rounded-sm w-full max-sm:w-[100vw] max-sm:max-w-[100vw] max-sm:ml-[-16px] mt-[80px] max-sm:aspect-square max-sm:object-cover"
              src={getStrapiMedia(article?.data.attributes.headerImage?.data?.attributes.url)}
            />
          </>
        )}
      </div>
      <div className="md:max-w-[926px] mt-[80px] mx-auto font-firstext text-left">
        <div className="sm:mt-[80px] w-full font-p22 mt-[112px] text-black mx-auto">
          {article && (
            <PlateEditor initialValue={getInitialValue()} />
          )}
        </div>
        <div className="flex justify-center mt-[120px]">
          <div className="flex flex-1 md:max-w-[688px] gap-4 items-center">
            <div className="mx-auto font-medium text-[32px] leading-[44px] tracking-[-0.02em] font-p22">
              Read next
            </div>
            <div className="flex-1 bg-black h-[1px]">
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[36px] max-w-[926px] mx-auto">
        <BlogGroup category="learn" limit={2} flexWrap={true} />
      </div>
    </div>
  );
}
