import BlogGroup from "@root/components/blog/BlogGroup";
import LoadingScreen from "@root/components/LoadingScreen";
import { useLearnPage } from "@root/hooks/useLearnPage";
import { Helmet } from "react-helmet";

export default function LearnPage() {
  const { data: pageData, isLoading } = useLearnPage();

  if (isLoading)
    return <LoadingScreen />;

  return (
    <div className="text-black root-margin">
      <Helmet>
        <title>The Bloom Room | ARS Insights, AI Solutions & Revenue Growth Strategies</title>
        <meta name="description" content="Explore The Bloom Room, your go-to resource for AI-driven insights, thought leadership, and strategies in Autonomous Revenue Systems. Unlock new revenue growth opportunities and streamline your operations today." />
      </Helmet>
      <div className="lg:mx-[100px]">
        <div className="sm:pt-[200px] font-p22 pt-[112px] text-black mx-auto font-normal sm:text-[80px] text-[44px] sm:leading-[100px] leading-[50px] tracking-[-0.02em] max-sm:text-left">
          {pageData?.data?.attributes?.title}
        </div>
        <div className="mt-4 max-w-[610px] mx-auto font-normal sm:leading-[27px] sm:text-[18px] leading-[24px] text-[16px] text-center text-wrap max-sm:text-left">
          {pageData?.data?.attributes?.description}
        </div>
      </div>
      <div className="sm:mt-[120px] mt-[40px]">
        <BlogGroup category="learn" flexWrap={true} />
      </div>
    </div>
  );
}