import commonEmailDomains from 'email-providers/common.json';

export function validateEmail(email: string) {
  const regex =
    /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
  return regex.test(String(email).toLowerCase());
}

export function validateBusinessEmail(email: string) {
  if (!validateEmail(email))
    return false;
  const domain = (email.split('@')[1]).toLocaleLowerCase();
  if (commonEmailDomains.includes(domain))
    return false;
  return true;
}

export function getCardWidth(flexWrap: boolean = false) {
  let cardWidth, winWidth = window.innerWidth;
  if (flexWrap) {
    if (winWidth >= 1000) {
      
    }
  }
  if (winWidth >= 1200)
    cardWidth = 451;
  else if (winWidth >= 1024)
    cardWidth = winWidth * 0.37;
  else if (winWidth >= 768)
    cardWidth = winWidth * 0.44;
  else
    cardWidth = winWidth * 0.83;
  return cardWidth;
}

export function getCardSpacing() {
  const winWidth = window.innerWidth;
  if (winWidth >= 1000) {
    return 24;
  } else if (winWidth >= 640) {
    return 20;
  } else
    return 12;
}

export function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);
  
  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

  // Removing temporary elements from the DOM
  outer.parentNode?.removeChild(outer);

  return scrollbarWidth;
}

export function getRootMargin() {
  const headerDom = document.getElementById("header");
  return headerDom?.offsetLeft || 0;
}

export function getUniqueSlug(id: number, slug?: string) {
  if (slug) {
    const items = slug.split("/").filter(Boolean);
    if (!items.length)
      return `/article/${id}`;
    if (items.length === 1)
      return `/article/${id}-${items[0]}`;
    return '/' + items.slice(0, items.length - 1).join('/') + '/' + `${id}-${items[items.length - 1]}`;
  }
  return `/article/${id}`;
};

export function getIdFromSlug(slug: string) {
  return Number(slug.split("-")[0]) || 0;
}