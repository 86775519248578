import { ButtonHTMLAttributes, FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  url?: string;
  className?: string;
};

export const Button: FC<PropsWithChildren<IButtonProps>> = ({ children, url, className, ...props })  => {
  const button = (
    <button className={`${className} text-sm justify-center items-center text-white bg-black rounded-full py-2 font-firstext outline-none focus:outline-none z-[100]
      border border-black enabled:hover:bg-white enabled:hover:border-black enabled:hover:text-black enabled:transition-all enabled:duration-200 enabled:hover:translate-x-[-2px] enabled:hover:translate-y-[-3px] enabled:hover:shadow-[2px_3px_0px_black]
    `} {...props}>
      {children}
    </button>
  );

  if (url) {
    return (
      <Link to={url}>
        {button}
      </Link>
    );
  }

  return <>{button}</>;
}