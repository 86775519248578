import { Button } from "@root/components/Button";
import { QuestionButton } from "@root/components/QuestionButton";
import { SectionTitle } from "@root/components/SectionTitle";
import BlogGroup from "@root/components/blog/BlogGroup";
import { useProductPage } from "@root/hooks/useProductPage";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { useEffect, useState } from "react";
import { getStrapiMedia } from "@root/api/strapi";
import VideoPlayer from "@root/components/VideoPlayer";
import LoadingScreen from "@root/components/LoadingScreen";
import { Helmet } from "react-helmet";

export default function HomePage() {
  const { data: pageData, isLoading } = useProductPage();
  const [headerAnimationUrl, setHeaderAnimationUrl] = useState('');

  useEffect(() => {
    const animUrl = window.innerWidth >= 768 ? pageData?.data.attributes.bloomHeadlineHorizontalAnimation.data.attributes.url : pageData?.data.attributes.bloomHeadlineVerticalAnimation.data.attributes.url;
    if (animUrl) {
      setHeaderAnimationUrl(getStrapiMedia(animUrl) as string);
    }
  }, [pageData]);

  if (isLoading)
    return <LoadingScreen />;

  return (
    <div className="text-black">
      <Helmet>
        <title>Bloom ARS | AI-Driven Revenue Automation & Growth Solutions</title>
        <meta name="description" content="Transform your business with Bloom’s AI-driven Autonomous Revenue Systems (ARS). Explore innovative solutions to automate growth, streamline operations, and enhance customer experiences." />
      </Helmet>
      <div className="root-margin">
        <div className="lg:mx-[100px]">
          <div className="mx-auto pt-[80px] max-w-[1000px]">
            {headerAnimationUrl && (
              <div className="max-sm:pt-[40px] pb-[40px]">
                <DotLottieReact
                  src={headerAnimationUrl}
                  loop={false}
                  autoplay
                />
              </div>
            )}
          </div>
        </div>      
        <div className="mx-auto max-w-[950px] font-p22">
          <div className="font-bold sm:text-[32px] text-[24px] sm:leading-[45px] leading-[30px] tracking-[-0.01em] text-black">
            <div dangerouslySetInnerHTML={{ __html: (pageData?.data?.attributes?.introTitle || '') }} />
            <div className="mt-6 font-normal" dangerouslySetInnerHTML={{ __html: (pageData?.data?.attributes?.introContent || '') }} />
            <div className="mt-8">
              <Button className="w-[180px] h-[48px] py-3 px-6 !text-[16px] leading-6" url={pageData?.data?.attributes?.requestAccessUrl}>Request access</Button>
            </div>
          </div>
        </div>
        <div className="md:mt-[200px] sm:mt-[160px] mt-[100px]">
          <div>
            <SectionTitle>
              ARS for success
            </SectionTitle>
            <div className="flex justify-between gap-[49px] mt-4">
              <div className="text-lg leading-[27px] max-w-[700px] text-left">
                Bloom embeds smart services into business operations, unblocking revenue growth through bespoke integration, intelligent automation, and real-time optimization.
              </div>
              <div className="max-md:hidden">
                <QuestionButton url={pageData?.data.attributes.questionUrl}>
                  Read our Definitive guide to ARS
                </QuestionButton>
              </div>
            </div>
            <div className="max-sm:ml-[-16px] max-sm:w-[100vw]">
              <VideoPlayer src={getStrapiMedia(pageData?.data.attributes.arsStoryVideo.data.attributes.url)} />
            </div>
            <div className="md:hidden mt-[64px]">
              <QuestionButton url={pageData?.data.attributes.questionUrl}>
                Read our Definitive guide to ARS
              </QuestionButton>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="sm:mt-[160px] mt-[100px]">
          <BlogGroup category="bloomEdge" title={pageData?.data?.attributes?.bloomEdgeGroupTitle} />
        </div>
        <div className="sm:mt-[160px] mt-[100px]">
          <BlogGroup category="valueArs" title={pageData?.data?.attributes?.valueArsGroupTitle} lineClamp={3} limit={3} />
        </div>
      </div>
      <div className="root-margin">
        <div className="mt-[162px] border-black border-y-[1px] bg-gradient-to-t w-full h-[451px] text-center flex justify-center items-center">
          <div className="max-sm:hidden border-l border-solid border-black h-[calc(100%-16px)]"></div>
          <div className="flex-1 h-full sm:overflow-hidden overflow-y-clip relative">
            <div className="absolute flex flex-col justify-center items-center w-full h-full z-50">
              <div className="sm:w-[540px] w-[342px]">
                <div className="leading-[15px] text-xs tracking-[0.08em] font-medium text-black uppercase">
                  {pageData?.data?.attributes?.unlockSectionTitle}
                </div>
                <div className="sm:text-[40px] text-[32px] tracking-[-0.02em] sm:leading-[50px] leading-[40px] font-medium mt-4 text-black font-p22" dangerouslySetInnerHTML={{__html: pageData?.data?.attributes?.unlockSectionDescription || ''}}>
                </div>
                <div className="mt-8">
                  <Button className="w-[180px] h-[48px] py-3 px-6 !text-[16px]" url="/signup">Talk to our team</Button>
                </div>
              </div>
            </div>
            <div className="w-[70%] h-full mt-[18%] mx-auto flex-shrink-0 rounded-full opacity-10 bg-gradient-to-r from-yellow-500 via-orange-500 to-purple-700 filter blur-[120px] animate-gradient">
            </div>
          </div>
          <div className="max-sm:hidden border-l border-solid border-black h-[calc(100%-16px)]"></div>
        </div>
      </div>
    </div>
  );
}