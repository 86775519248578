import { useFooter } from "@root/hooks/useFooter"
import Logo from "./Logo";
import { MenuItem } from "./MenuItem";
import SocialIcon from "./SocialIcon";
import { usePageWithArticle } from "@root/hooks/usePageWithArticle";

export default function Footer() {
  const { data: footerData } = useFooter();
  const { data: pageArticleData } = usePageWithArticle();
  
  const getCopyright = () => {
    return (
      <div className="flex items-center font-normal text-sm leading-[17.5px] text-gray-500">
        {footerData?.data?.attributes?.copyright}
      </div>
    );
  };

  const menuItems = [
    {
      title: 'Privacy policy',
      url: pageArticleData?.data.attributes.privacyPolicy
    },
    {
      title: 'Terms of use',
      url: pageArticleData?.data.attributes.termsOfUse
    }
  ]

  return (
    <div className="pt-[160px] pb-[44px] root-margin">
      <div className="flex justify-between items-center">
        <Logo />
        <div className="max-sm:hidden flex gap-8">
          {menuItems.map((item, index) => (
            <MenuItem key={index} url={item.url} className="font-normal !text-sm leading-[17.5px] tracking-[0em]">
              {item.title}
            </MenuItem>
          ))}
          {getCopyright()}      
        </div>
        <div className="flex gap-5 items-center">
          {footerData?.data?.attributes?.socialProfiles?.data?.map((item) => (
            <SocialIcon key={item.id} imageSrc={item?.attributes?.imageSrc?.data?.attributes?.url} url={item?.attributes?.url} platform={item.attributes.platform} />
          ))}
        </div>
      </div>
      <div className="sm:hidden">
        <div className="flex gap-8 mt-10">
          {menuItems.map((item, index) => (
            <MenuItem key={index} url={item.url} className="font-medium leading-[17.5px] tracking-[0em]">
              {item.title}
            </MenuItem>
          ))}
        </div>
        <div className="mt-3">
          {getCopyright()}
        </div>
      </div>
    </div>
  )
}