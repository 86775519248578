import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import LearnPage from '@root/pages/LearnPage';
import AboutPage from '@root/pages/AboutPage';
import ArticlePage from '@root/pages/ArticlePage';
import SignupPage from '@root/pages/SignupPage';
import Error404Page from '@root/pages/Error404Page';

export default function Router() {
  return (
    <Routes>
      <Route path='/' index element={<HomePage />} />
      <Route path='/product' element={<HomePage />} />
      <Route path='/learn' element={<LearnPage />} />
      <Route path='/about' element={<AboutPage />} />
      <Route path='/signup' element={<SignupPage />} />
      <Route path='/article/:articleSlug' element={<ArticlePage />} />
      <Route path='/learn/:articleSlug' element={<ArticlePage />} />
      <Route path="/not-found" element={<Error404Page />} />
      <Route path="*" element={<Navigate to='/not-found' replace />} />
    </Routes>
  );
}