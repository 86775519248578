import axios from 'axios';
import { Environment } from './constants';

export const strapiClient = axios.create({
    baseURL: Environment.STRAPI_API_URL
});

strapiClient.interceptors.request.use((config) => {
    const token = Environment.STRAPI_API_TOKEN;
    // config.headers["Access-Control-Allow-Origin"] = '*'
    // config.headers["Access-Control-Allow-Headers"] = '*'
    // config.headers["Access-Control-Allow-Methods"] = '*'
    config.headers["Accept"] = "application/json";
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["Content-Type"] = "application/json";
    return config;
});

export const fetcher = (url: string) => {
    return strapiClient.get(url).then(res => res.data);
}

// export const importExport = async () => {
//     const { data: articleList } = (await strapiClient.get(`/articles?populate[background][populate]=*&populate[title][populate]=*&sort=id:asc&publicationState=preview`)).data;
//     console.log({ articleList });
//     // for (const article of articleList) {
//     //     const body = article.attributes;
//     //     await strapiClient.post(`/cards`, {
//     //         data: {
//     //             ...body,
//     //             blogContent: undefined,
//     //             textOnly: undefined
//     //         }
//     //     });
//     // }
// };

// importExport();