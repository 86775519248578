import { getStrapiMedia } from "@root/api/strapi";
import { Link } from "react-router-dom";

interface ISocialIconProps {
  imageSrc: string;
  url: string;
  platform: string;
};

export default function SocialIcon({ imageSrc, url, platform }: ISocialIconProps) {
  return (
    <Link to={url || '#'}>
      <img src={getStrapiMedia(imageSrc)} alt={platform} />
    </Link>
  )
}