import { fetcher } from '@root/api/hook'
import { ISAboutPage } from '@root/api/type'
import useSWR from 'swr'

export function useAboutPage() {
  const { data, error } = useSWR(`/about-page?populate=*`, fetcher)

  return {
    data: data as (ISAboutPage | undefined),
    isLoading: !error && !data,
    isError: !!error
  }
}