import BlogItem from "./BlogItem";
import { SectionTitle } from "../SectionTitle";
import LeftVectorIcon from '../../assets/left_vector.svg';
import RightVectorIcon from '../../assets/right_vector.svg';
import { useEffect, useRef, useState } from "react";
import { getCardSpacing, getCardWidth, getRootMargin } from "@root/api/utils";
import { useLocation } from "react-router-dom";
import { useCards } from "@root/hooks/useCards";

interface IBlogGroupProps {
  category?: 'bloomEdge' | 'learn' | 'valueArs' | 'general' | 'guidePrinciple';
  title?: string;
  flexWrap?: boolean;
  lineClamp?: number;
  clickable?: boolean;
  limit?: number;
}

export default function BlogGroup({ category, title, flexWrap, lineClamp, limit, clickable }: IBlogGroupProps) {
  const { data: cards } = useCards();
  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const [disableLeft, setDisableLeft] = useState(true);
  const [disableRight, setDisableRight] = useState(false);
  const [isScrollable, setScrollable] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const timer = setInterval(() => {
      checkScrollEnds();
    }, 500);
    return () => clearInterval(timer);
  }, []);

  function checkScrollEnds() {
    const sc = scrollContainer.current;
    if (sc) {
      const scrollLeftMax = sc.scrollWidth - sc.clientWidth;
      setDisableLeft(sc.scrollLeft <= 5);
      setDisableRight(scrollLeftMax - sc.scrollLeft <= 5);
      setScrollable(scrollLeftMax > 5);
    }
  }

  const handleScrollLeft = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scroll({ left: scrollContainer.current.scrollLeft - getCardWidth() - getCardSpacing(), behavior: 'smooth' });
    }
  };

  const handleScrollRight = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scroll({ left: scrollContainer.current.scrollLeft + getCardWidth() + getCardSpacing(), behavior: 'smooth' });
    }
  };

  const getCards = () => {
    let result = cards?.data?.filter(item => item?.attributes?.category === (category || 'general')) || [];
    if (limit) {
      result = result.slice(0, limit);
    }
    return result;
  };

  const getContainerClassName = () => {
    if (pathname === '/about') {
      return {
        maxWContainer: 'max-w-container-926',
        blogWrapper: 'blog-wrapper-926',
        gutter: '--gutter-size-926'
      };
    }
    return {
      maxWContainer: 'max-w-container-1400',
      blogWrapper: 'blog-wrapper-1400',
      gutter: '--gutter-size-1400'
    };
  };

  const cardData = getCards();

  return (
    <div className="relative">
      {isScrollable && !flexWrap && (
        <div className={`absolute ${title ? 'top-4' : 'sm:top-[-88px] top-[-72px]'} right-0 z-[100]`}>
          <div className={`flex justify-center gap-0 items-center max-sm:hidden`}>
            <button
              className="w-10 h-10 p-0 flex justify-center items-center bg-transparent disabled:opacity-40 disabled:hover:!border-transparent border border-transparent hover:border-[rgba(0,0,0,0.15)] rounded-full focus:outline-none duration-300 transition-colors ease-in-out"
              onClick={handleScrollLeft}
              disabled={disableLeft}
            >
              <img src={LeftVectorIcon} />
            </button>
            <button
              className="w-10 h-10 p-0 flex justify-center items-center bg-transparent disabled:opacity-40 disabled:hover:!border-transparent border border-transparent hover:border-[rgba(0,0,0,0.15)] rounded-full focus:outline-none duration-300 transition-colors ease-in-out"
              onClick={handleScrollRight}
              disabled={disableRight}
            >
              <img src={RightVectorIcon} />
            </button>
            <div className="shrink-0" style={{ width: `calc(var(--gutter-size-1400) - 16px)` }}></div>
          </div>
        </div>
      )}
      {title && (
        <div className={`flex relative items-center sm:mb-12 mb-8 ${getContainerClassName().maxWContainer}`}>
          {title && <SectionTitle>{title}</SectionTitle>}
        </div>
      )}
      <div className={`w-full ${getContainerClassName().maxWContainer}`}>
        {!flexWrap && (
          <div
            className={`flex w-[var(--document-width)] ${getContainerClassName().blogWrapper}`}
          >
            <div
              className={`flex overflow-x-auto overflow-y-hidden snap-x snap-mandatory no-scrollbar relative whitespace-break-spaces`}
              ref={scrollContainer}
              style={{
                scrollPaddingLeft: `var(${getContainerClassName().gutter})`,
                scrollPaddingRight: `var(${getContainerClassName().gutter})`
              }}
            >
              <div className="shrink-0" style={{ width: `var(${getContainerClassName().gutter})` }}></div>
              {cardData.map((item, index) => (
                <BlogItem
                  id={item.attributes.article?.data?.id}
                  key={item.id}
                  description={item.attributes.description}
                  url={item.attributes.url}
                  linkName={item.attributes.linkName}
                  subtitle={item.attributes.subtitle}
                  title={item.attributes.title}
                  background={item.attributes.background}
                  caption={item.attributes.caption}
                  lineClamp={lineClamp}
                  value={item.attributes.value}
                  className={`${limit === 2 && 'md:!max-w-[50%]'} ${index < cardData.length - 1 && 'md:mr-6 sm:mr-5 mr-3'}`}
                  clickable={clickable}
                  slug={item.attributes.article?.data?.attributes.slug}
                  opentype={item.attributes.opentype}
                />
              ))}
              <div className="shrink-0" style={{ width: `var(${getContainerClassName().gutter})` }}></div>
            </div>
          </div>
        )}
        {flexWrap && (
          <div className={`${limit === 2 ? 'grid-container-2' : 'grid-container'}`}>
            {cardData.map((item) => (
              <BlogItem
                id={item.attributes.article?.data?.id}
                key={item.id}
                description={item.attributes.description}
                url={item.attributes.url}
                linkName={item.attributes.linkName}
                subtitle={item.attributes.subtitle}
                title={item.attributes.title}
                background={item.attributes.background}
                caption={item.attributes.caption}
                lineClamp={lineClamp}
                value={item.attributes.value}
                clickable={clickable}
                slug={item.attributes.article?.data?.attributes.slug}
                opentype={item.attributes.opentype}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}