import { IUser } from "@root/api/form";
import { getStrapiMedia } from "@root/api/strapi";
import { validateBusinessEmail } from "@root/api/utils";
import { emailValidatorOptions, firstNameValidatorOptions, lastNameValidatorOptions } from "@root/api/validators";
import { Button } from "@root/components/Button";
import { InputField } from "@root/components/InputField";
import LoadingScreen from "@root/components/LoadingScreen";
import { useRegisterUser } from "@root/hooks/useRegisterUser";
import { useSignupPage } from "@root/hooks/useSignupPage";
import { AxiosError } from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";

export default function SignupPage() {
  const { data: pageData, isLoading } = useSignupPage();
  const [isSuccess, setSuccess] = useState(false);
  const {
    formState: { errors, isValid, isSubmitted, isSubmitting },
    register,
    getValues,
    clearErrors,
    setError,
    trigger,
    handleSubmit
  } = useForm<IUser>();

  const registerUser = useRegisterUser();

  const onSubmit = async (values: IUser) => {
    const isValid = checkValidation() && (await trigger());
    if (!isValid)
      return;
    try {
      await registerUser(values);
      setSuccess(true);
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status) {
          setError('root.server', { message: err.response.data.message });
        } else {
          setError('root.server', { message: 'Something went wrong' });
        }
      } else {
        setError('root.server', { message: 'Something went wrong' });
      }
      setSuccess(false);
    }
  };

  const checkValidation = () => {
    const { email } = getValues();
    let valid = true;
    
    if (validateBusinessEmail(email)) {
      clearErrors('email');
    } else {
      setError('email', { message: 'Please enter a business email address' });
      valid = false;
    }

    return valid;
  };

  if (isLoading)
    return <LoadingScreen />;

  return (
    <div className="flex max-md:flex-col text-black sm:pt-20 pt-12 root-margin text-left h-[calc(100vh)]">
      <Helmet>
        <title>Connect with Bloom ARS Experts | Unlock AI-Driven Revenue Growth</title>
        <meta name="description" content="Discover how Bloom’s Autonomous Revenue System (ARS) can accelerate your business growth. Connect with our team to explore AI-powered strategies and solutions tailored to your revenue goals." />
      </Helmet>
      <div className="flex-1 sm:pt-20 pt-12">
        <div className="lg:pr-[131px] md:pr-[64px] md:w-1/2">
          <div className="font-p22 sm:text-[40px] text-[32px] font-medium sm:leading-[56px] leading-[44px] tracking-[-0.8px]">
            {pageData?.data.attributes.title}
          </div>
          <div className="mt-6">
            {pageData?.data.attributes.description}
          </div>
          {isSuccess && (
            <div className="mt-12 bg-[#045244] rounded-sm h-[354px] text-center pt-8 text-white items-center relative flex flex-col">
              <div className="text-[32px] font-medium leading-[44px] tracking-[-0.02em] font-p22">{pageData?.data.attributes.successTitle}</div>
              <div className="max-w-[300px] mt-3 font-firstext font-normal text-[16px] leading-[24px]">{pageData?.data.attributes.successDescription}</div>
              <div className="absolute bottom-0">
                <img src={getStrapiMedia(pageData?.data.attributes.foregroundImage.data.attributes.url)} />
              </div>
            </div>
          )}
          {!isSuccess && (
            <form onSubmit={handleSubmit(onSubmit)} className="mt-12">
              {isSubmitted && (!isValid || errors.root) && (
                <div className="mb-6 px-4 py-3 bg-[rgba(238,46,92,0.12)] rounded-[4px] leading-[21px] font-normal text-[14px]">
                  {errors.root?.server.message || "Please correct the errors below and submit your form again."}
                </div>
              )}
              <div>
                <div className="flex gap-4 max-sm:flex-col">
                  <InputField id="firstName" className="flex-1" placeholder="First name" {...register('firstName', firstNameValidatorOptions)} autoFocus hint={errors.firstName?.message} />
                  <InputField id="lastName" className="flex-1" placeholder="Last name" {...register('lastName', lastNameValidatorOptions)} hint={errors.lastName?.message} />
                </div>
                <div className="mt-4">
                  <InputField id="workEmail" className="w-full" placeholder="Work email" {...register('email', emailValidatorOptions)} hint={errors.email?.message} />
                </div>
              </div>
              <div className="mt-8">
                <Button className="w-[118px] h-[40px]" type="submit" disabled={isSubmitting}>Get started</Button>
              </div>
            </form>
          )}
        </div>
      </div>
      <div className="flex-1 max-md:mt-16 max-md:w-[100vw] max-md:ml-[-32px] max-sm:ml-[-16px] md:fixed md:top-0 md:left-[50vw] md:w-[50vw] md:h-[100vh] md:z-[99999]">
        <img src={getStrapiMedia(pageData?.data.attributes.backgroundImage.data.attributes.url)} className="object-cover h-full max-sm:aspect-square" />
      </div>
    </div>
  )
}