import { FC, PropsWithChildren } from "react";
import RightArrowIcon from '../assets/right_arrow.svg';
import { Link } from "react-router-dom";

interface IGuideButtonProps {
  url?: string;
  className?: string;
  imageSrc?: string;
};

export const GuideButton: FC<PropsWithChildren<IGuideButtonProps>> = ({ children, url, imageSrc, className })  => {
  return (
    <Link to={url || '#'}>
      <div className={`${className} w-full py-[18px] items-center flex justify-between border-y-[1px] bg-white border-black text-black sm:pr-4 max-sm:flex-1`}>
        <div className="flex gap-6 max-sm:justify-between max-sm:flex-1 max-sm:flex-row-reverse">
          <img className="w-[90px] h-[120px] max-sm:w-[120px] max-sm:h-[160px] object-cover rounded-sm" src={imageSrc} />
          <div className="flex flex-col text-left justify-center">
            <div className="sm:text-[24px] text-[20px] leading-[30px] tracking-[-0.01em] font-medium font-p22">Definitive Guide to ARS</div>
            <div className="mt-3 sm:text-[16px] text-[14px] font-normal leading-6 max-w-[349px]">
              {children}
            </div>
          </div>
        </div>
        <div className="max-sm:hidden">
          <button className="w-[44px] h-[44px] rounded-full flex justify-center items-center p-0 bg-black outline-none border-none focus:outline-none">
            <img src={RightArrowIcon} />
          </button>
        </div>
      </div>
    </Link>
  );
}