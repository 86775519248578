import { useContactUs } from "@root/hooks/useContactUs";
import { Button } from "./Button";

export default function ContactUs() {
  const { data: contactUsData } = useContactUs();
  return (
    <div className="text-left text-black">
      <div className="font-medium sm:text-[40px] text-[32px] sm:leading-[56px] leading-[44px] tracking-[-0.02em] font-p22">{contactUsData?.data?.attributes?.title}</div>
      <div className="mt-6 sm:text-[18px] text-[16px]">
        {contactUsData?.data?.attributes?.description}
      </div>
      <div className="mt-8">
        <Button className="w-[152px] h-[48px]" url={contactUsData?.data.attributes.url}>Get in touch</Button>
      </div>
    </div>
  )
}