import { FC, PropsWithChildren } from "react";

interface ISectionTitleProps {
  url?: string;
};

export const SectionTitle: FC<PropsWithChildren<ISectionTitleProps>> = ({ children })  => {
  return (
    <div className="sm:text-[40px] text-[32px] sm:leading-[56px] leading-[44px] sm:tracking-[-0.8px] tracking-[-0.64px] font-medium text-left font-p22">
      {children}
    </div>
  )
}