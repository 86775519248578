import { getStrapiMedia } from "@root/api/strapi";
import { IBackground, ITitle } from "@root/api/type";
import CardAnimationVideo from "../CardAnimationVideo";
import { useEffect, useRef, useState } from "react";
import { getCardWidth, getUniqueSlug } from "@root/api/utils";
import { Link, useNavigate } from "react-router-dom";

interface IBlogItemProps {
  id: number;
  imageSrc?: string;
  title?: ITitle;
  subtitle?: string;
  description?: string;
  value?: string;
  linkName?: string;
  url?: string;
  background: IBackground;
  caption?: string;
  lineClamp?: number;
  className?: string;
  clickable?: boolean;
  slug?: string;
  opentype?: boolean;
}

export default function BlogItem({
  id,
  background,
  title,
  subtitle,
  description,
  value,
  linkName,
  url,
  caption,
  lineClamp,
  className,
  clickable,
  slug,
  opentype,
}: IBlogItemProps) {
  const cardRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [cardRef.current, descriptionRef.current]);

  useEffect(() => {
    const timer = setTimeout(handleResize, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const onCardClick = () => {
    if (clickable === false) return;
    if (url?.startsWith("http")) window.open(url, "_blank");
    else navigate(url || getUniqueSlug(id, slug));
  };

  const handleResize = () => {
    if (cardRef.current && descriptionRef.current) {
      const cardWidth = getCardWidth();
      cardRef.current.style.width = cardWidth.toFixed(2) + "px";
      descriptionRef.current.style.width = cardRef.current.style.width;
    }
  };

  const getStyle = () => {
    const style: Record<string, string> = {
      backgroundSize: "cover",
    };
    const backgroundImageUrl = getStrapiMedia(
      background?.backgroundImage?.data?.attributes.url
    );
    const backgroundColor = background?.backgroundColor;
    if (backgroundColor) {
      style["backgroundColor"] = backgroundColor;
    }
    if (backgroundImageUrl) {
      style["backgroundImage"] = `url(${backgroundImageUrl})`;
    }
    if (!background?.foregroundImage?.data) {
      const gradient = `linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(255,255,255,0), rgba(255,255,255,0), rgba(255,255,255,0), rgba(0,0,0,0.2))`;
      if (style["backgroundImage"]) {
        style["backgroundImage"] = gradient + ", " + style["backgroundImage"];
      } else {
        style["backgroundImage"] = gradient;
      }
    }
    return style;
  };

  return (
    <div className={className}>
      <div
        className={`relative flex-1 h-auto ${
          clickable !== false && "cursor-pointer"
        } flex items-start justify-center flex-col rounded-[4px] overflow-hidden`}
        style={{ aspectRatio: 0.75 }}
        onClick={onCardClick}
        ref={cardRef}
      >
        <div
          className={`absolute w-full h-full bg-no-repeat bg-center bg-cover animate-background-size ${
            background?.backgroundImage?.data?.attributes.url
              ? "animate-background-size"
              : "z-[-1]"
          }`}
          style={getStyle()}
        />
        <div className="text-white pointer-events-none text-xs font-medium leading-4 tracking-[0.08em] top-[20px] text-left uppercase absolute sm:px-6 px-5 z-10">
          {subtitle}
        </div>
        <div
          className={`
          text-white pointer-events-none font-medium text-pretty text-left sm:px-6 px-5 z-10 ${
            title?.fontFamily === "Serif"
              ? "font-p22"
              : title?.fontFamily === "Sans-serif"
              ? "font-firstext"
              : "font-firsneue"
          }
          ${
            title?.fontSize === "Small" &&
            "md:text-[24px] md:leading-[30px] tracking-[-0.01em] text-[20px] leading-[24px] absolute bottom-[20px] w-full"
          }
          ${
            title?.fontSize === "Medium" &&
            "md:text-[32px] md:leading-[44px] lg:tracking-[-0.02em] text-[24px] leading-[30px] tracking-[-0.01em] absolute bottom-[20px]"
          }
          ${
            title?.fontSize === "Large" &&
            "md:text-[40px] md:leading-[50px] lg:tracking-[-0.02em] text-[32px] leading-[44px] tracking-[-0.01em] text-center mb-4"
          }
        `}
          style={{
            fontVariantAlternates: opentype
              ? "styleset(alt-a, alt-g)"
              : "unset",
          }}
        >
          {title?.text}
        </div>
        {value && (
          <div className="text-white mb-4 pointer-events-none font-normal font-p22 text-[64px] leading-[80px] tracking-[-0.02em] text-center w-full absolute z-50">
            {value}
          </div>
        )}
        {caption && (
          <div className="text-center pointer-events-none text-[16px] leading-6 font-medium bottom-[21px] absolute text-white self-center z-10">
            {caption}
          </div>
        )}
        {background?.foregroundImage?.data && (
          <div
            className={`mx-auto flex items-center px-8 h-full ${
              subtitle !== "Data Integrations" && "zoom"
            }`}
            style={{
              maskImage: background?.foregroundMask?.data
                ? `url(${getStrapiMedia(
                    background?.foregroundMask?.data?.attributes.url
                  )})`
                : "unset",
              maskRepeat: "no-repeat",
              maskSize: "75% 75%",
              maskPosition: "center",
            }}
          >
            <img
              src={getStrapiMedia(
                background?.foregroundImage?.data?.attributes.url
              )}
              className={`flex-1 mb-4 max-lg:w-[90%] ${
                background?.foregroundMask?.data && "bg-cover h-full"
              }`}
            />
          </div>
        )}
        {background?.foregroundAnimation?.data && (
          <div className="w-full">
            <CardAnimationVideo
              src={getStrapiMedia(
                background?.foregroundAnimation.data.attributes.url
              )}
            />
          </div>
        )}
      </div>
      {description && (
        <div
          ref={descriptionRef}
          className="sm:px-6 px-5 mt-6 lg:w-[450px] md:w-[380px] w-[342px] font-normal sm:leading-[24px] sm:text-[16px] text-[14px] leading-[21px] text-left text-wrap"
        >
          {description && (
            <div
              style={
                lineClamp
                  ? {
                      WebkitLineClamp: lineClamp,
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }
                  : {}
              }
            >
              {description}
            </div>
          )}
          {linkName && (
            <div className="mt-4">
              <Link
                className="underline underline-offset-[3px] text-black font-normal leading-6 hover:text-black"
                to={url || getUniqueSlug(id, slug)}
                target={url ? "_blank" : "_self"}
              >
                {linkName}
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
