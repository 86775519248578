'use client';

import React, { useEffect, useRef, useState } from 'react';
import { cn } from '@udecode/cn';
import { CommentsProvider } from '@udecode/plate-comments';
import { createPlateEditor, getNode, Plate, useEditorState } from '@udecode/plate-common';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { commentsUsers, myUserId } from '../../lib/plate/comments';
import { MENTIONABLES } from '../../lib/plate/mentionables';
import { plugins } from '../../lib/plate/plate-plugins';
import { CommentsPopover } from './plate-ui/comments-popover';
import { CursorOverlay } from './plate-ui/cursor-overlay';
import { Editor } from './plate-ui/editor';
import { FixedToolbar } from './plate-ui/fixed-toolbar';
import { FixedToolbarButtons } from './plate-ui/fixed-toolbar-buttons';
import { FloatingToolbar } from './plate-ui/floating-toolbar';
import { FloatingToolbarButtons } from './plate-ui/floating-toolbar-buttons';
import { MentionCombobox } from './plate-ui/mention-combobox';

interface IPlateEditorProps {
  initialValue?: any;
};

export default function PlateEditor(props: IPlateEditorProps) {
  const containerRef = useRef(null);

  return (
    <DndProvider backend={HTML5Backend}>
      {/* <CommentsProvider users={commentsUsers} myUserId={myUserId}> */}
        <Plate plugins={plugins} initialValue={props.initialValue}>
          <div
            ref={containerRef}
            className={cn(
              'relative',
              // Block selection
              '[&_.slate-start-area-left]:!w-[64px] [&_.slate-start-area-right]:!w-[64px] [&_.slate-start-area-top]:!h-4'
            )}
          >
            {/* <FixedToolbar>
              <FixedToolbarButtons />
            </FixedToolbar> */}

            <Editor
              className="px-[96px] py-16"
              autoFocus
              focusRing={false}
              variant="ghost"
              size="md"
              readOnly={true}
            />

            {/* <FloatingToolbar>
              <FloatingToolbarButtons />
            </FloatingToolbar> */}

            {/* <MentionCombobox items={MENTIONABLES} /> */}

            {/* <CommentsPopover /> */}

            {/* <CursorOverlay containerRef={containerRef} /> */}
          </div>
        </Plate>
      {/* </CommentsProvider> */}
    </DndProvider>
  );
}
